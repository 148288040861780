import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state";
import { Text, Image, Icon } from "@atoms";
import loadable from "@loadable/component";
import { useScroll } from "framer-motion";
import { toggleBodyScroll } from "@utils";

const Audiogram = loadable(() => import("@organisms/Audiogram"));
const VideoPlayerInline = loadable(() =>
  import("@organisms/VideoPlayerInline")
);

const SideScrollModal = () => {
  const [{ sideScroll }, dispatch] = useAppState();
  const { active, content } = sideScroll;
  const scroll = useRef(null);
  const [progress, setProgress] = useState(0);
  const { scrollXProgress } = useScroll({
    container: scroll,
  });

  const isMobileSafari =
    typeof window !== "undefined"
      ? (navigator?.userAgent.indexOf("iPhone") !== -1 ||
          navigator?.userAgent.indexOf("iPad") !== -1) &&
        navigator?.userAgent.indexOf("Safari") !== -1
      : null;

  const { intro, slides } = content || {};

  useEffect(() => {
    setProgress(0);

    if (active) {
      document.documentElement.classList.add("is-locked");
      setTimeout(() => toggleBodyScroll(true), 1000);
    } else {
      document.documentElement.classList.remove("is-locked");
      toggleBodyScroll(!active);
    }
  }, [active]);

  useEffect(() => {
    const unsubscribe = scrollXProgress.onChange(v => {
      setProgress(v);
    });
    return () => {
      unsubscribe();
    };
  }, [scroll.current, slides]);

  useEffect(() => {
    let scrollListener;
    if (active && scroll.current) {
      if (!isMobileSafari) {
        scrollListener = scroll.current.addEventListener("wheel", evt => {
          evt.preventDefault();
          scroll.current.scrollLeft += evt.deltaY + evt.deltaX;
        });
      } else {
        scrollListener = scroll.current.addEventListener("scroll", evt => {
          scroll.current.scrollLeft = evt?.target?.scrollLeft;
        });
      }
    } else if (!active && scroll.current && content) {
      setTimeout(() => dispatch({ type: "clearSideScrollContent" }), 1000);

      if (isMobileSafari)
        scroll.current?.removeEventListener(
          isMobileSafari ? "scroll" : "wheel",
          scrollListener
        );
    }
    return () =>
      scroll.current?.removeEventListener(
        isMobileSafari ? "scroll" : "wheel",
        scrollListener
      );
  }, [active, scroll.current]);

  return (
    <div
      className={classNames(
        "fixed inset-0 z-[700] h-screen w-screen bg-viridian text-black transition duration-300 ease-in-out",
        { "translate-x-full scale-50": !active }
      )}
    >
      <button
        type="button"
        onClick={() => dispatch({ type: "disableSideScroll" })}
        className="absolute right-0 top-0 z-[710] p-3"
      >
        <Icon name="close" className="h-6 w-6 text-white" />
      </button>
      <button
        type="button"
        onClick={() => {
          if (progress < 1) {
            scroll.current.scrollBy({
              left: window.innerWidth,
              behavior: "smooth",
            });
          } else {
            dispatch({ type: "disableSideScroll" });
          }
        }}
        className="absolute bottom-8 right-8 z-[710] rounded-full border-2 border-white p-3 text-white"
      >
        <Icon
          name="arrow"
          className={classNames("h-6 w-6", { "rotate-180": progress >= 1 })}
        />
      </button>
      {intro && (
        <div className="pointer-events-none absolute bottom-0 left-8 z-[710] translate-y-1 text-white md:translate-y-2 lg:translate-y-4">
          <Text variant="h2">{intro.heading}</Text>
        </div>
      )}
      <ul
        ref={scroll}
        className="absolute inset-0 z-[701] flex h-screen w-screen overflow-y-hidden overflow-x-scroll"
      >
        {slides &&
          slides.map((s, i, array) => {
            switch (s.type) {
              case "image":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex flex-shrink-0 items-center",
                      {
                        "bg-viridian text-white": i > 1,
                        "bg-coral": i <= 1,
                      }
                    )}
                  >
                    <div className="relative order-2 h-screen w-screen">
                      <Image
                        fast
                        ixParams={{ fit: "crop" }}
                        image={s.image}
                        className="pointer-events-none h-screen w-screen"
                        fill
                        draggable={false}
                      />
                    </div>
                    {s.copy && (
                      <div
                        className={classNames(
                          "flex w-[25rem] self-start px-14 py-14 sm:py-28",
                          {
                            "order-1 justify-end": s.reverse,
                            "order-3 justify-start": !s.reverse,
                          }
                        )}
                      >
                        <Text variant="body" className="prose max-w-xs">
                          {s.copy}
                        </Text>
                      </div>
                    )}
                  </li>
                );
              case "copy":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex flex-shrink-0 items-start",
                      {
                        "bg-viridian text-white": i > 1,
                        "bg-coral": i <= 1,
                      }
                    )}
                  >
                    <Text
                      tag="div"
                      variant="body"
                      className="auto-columns prose h-full max-w-xs px-14 py-14 sm:py-28"
                    >
                      {s.copy}
                    </Text>
                  </li>
                );
              case "headingCopy":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex flex flex-shrink-0 ",
                      {
                        "bg-viridian text-white": i > 1,
                        "bg-coral": i <= 1,
                      }
                    )}
                  >
                    <div className="flex">
                      <div
                        className={classNames(
                          "w-[30rem] py-14 pr-14 sm:py-28",
                          {
                            "-translate-x-1/4":
                              array[i - 1 || 0]?.type === "image" &&
                              !array[i - 1 || 0]?.copy,
                          }
                        )}
                      >
                        <Text variant="h2">{s.heading}</Text>
                      </div>
                      <div className="w-[25rem] px-14 py-14 sm:py-28 ">
                        <Text
                          variant="body"
                          className="auto-columns prose max-w-xs"
                        >
                          {s.copy}
                        </Text>
                      </div>
                    </div>
                  </li>
                );
              case "audiograms":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex flex-shrink-0 md:w-screen",
                      {
                        "bg-viridian text-white": i > 1,
                        "bg-coral": i <= 1,
                        "w-full": s?.audiograms?.length > 1,
                        "w-screen": s?.audiograms?.length <= 1,
                      }
                    )}
                  >
                    <Audiogram
                      {...s}
                      color={s?.audiograms?.length > 1 ? 0 : 1}
                      scroll
                    />
                  </li>
                );
              case "video":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex w-screen flex-shrink-0 items-center justify-center",
                      {
                        "bg-viridian text-white": i > 1,
                        "bg-coral": i <= 1,
                      }
                    )}
                  >
                    <div className="w-[50vw] min-w-[400px] px-14">
                      {typeof window !== "undefined" && (
                        <VideoPlayerInline url={s.videoUrl} {...s} />
                      )}
                    </div>
                  </li>
                );
              case "headingColumnedCopy":
                return (
                  <li
                    key={s.uid}
                    className={classNames(
                      "texture-pattern flex flex flex-shrink-0 items-start bg-coral",
                      {
                        // "bg-viridian text-white": i > 1,
                        // "bg-coral": i <= 1,
                      }
                    )}
                  >
                    <div className="h-full min-w-[25rem] px-14 py-14 sm:py-28">
                      <Text
                        variant="h3"
                        className="mb-8 max-w-sm text-viridian"
                      >
                        {s.heading}
                      </Text>
                      <div className="flex h-full flex-grow gap-4 sm:gap-8">
                        {s.copy.map(e => (
                          <Text
                            variant="body"
                            className="auto-columns prose flex-grow"
                          >
                            {e}
                          </Text>
                        ))}
                      </div>
                    </div>
                  </li>
                );
              default:
                return (
                  <li key={s.uid} className="h-screen flex-shrink-0">
                    {s.uid}
                  </li>
                );
            }
          })}
      </ul>
    </div>
  );
};

export default SideScrollModal;
